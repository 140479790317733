import gsap from "./gsap.min.js";
import ScrollTrigger from "./ScrollTrigger.min.js";
import { DrawSVGPlugin } from "./DrawSVGPlugin.min.js";
import { MotionPathPlugin } from "./MotionPathPlugin.min.js";
import  Alpine from 'alpinejs';
// import { alpineLogic } from "./alpinelogic.js";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(MotionPathPlugin);

gsap.defaults({ease: "none"});

const pulses = gsap.timeline({
  defaults: {
    duration: 0.05, 
    autoAlpha: 1,
    scale: 1.5, 
    transformOrigin: 'left center', 
    ease: "elastic(2.5, 1)"
}})
.to(".ball02, .text01", {}, 0.17)
.to(".ball03, .step1", {}, 0.28 )

.to(".ball04, .text03", {}, 0.4)
.to(".ball05, .step2", {}, 0.50)
.to(".ball05, .step2line", {}, 0.60)

.to(".ball06, .step3", {}, 0.64)
.to(".ball07, .step4", {}, 0.75)
.to(".ball08, .step5", {}, 0.90)
.to(".ball09, .step6", {}, 0.99)

const main = gsap.timeline({defaults: {duration: 1},
  scrollTrigger: {
    trigger: "#routeStart",
    scrub: true,
    start: "top center",
    end: "bottom center"
  }})
.to(".ball01", {duration: 0.01, autoAlpha: 1})
.from(".theLine", {drawSVG: 0}, 0)
.to(".ball01", {motionPath: {
  path: ".theLine",
  align:".theLine",
  alignOrigin: [0.5, 0.5],
}}, 0)
.add(pulses, 0);


Alpine.start()
window.Alpine = Alpine